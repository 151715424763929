import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import

import { SlotService } from "../../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

interface Props {}

interface State {
  mode: string;
  slots: any;
  games: any;

  withdraws: any;
  deposits: any;
}

export class SlotList extends Component<Props, State> {
  slotService = new SlotService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      withdraws: [],
      deposits: [],
      games: [],
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });
  }

  handleGame = (name: string) => {
    this.slotService.get_slot_by_company(name).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  };

  handleOpenSlot = (code: string, gameCompany : string) => {
    this.slotService.OpenSlot(code, gameCompany).then((data: any) => {
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      } else if (data.status === "fix_server") {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoFix,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      } else {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoPermission,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      }
    });
  };

  RenderGame = (info: any) => {
    if (info.isLocalImg == null 
      || info.imgUrl?.indexOf("http") >= 0) {
      return (
        <li
          className="SlotImg"
          style={{ backgroundImage: `url(${info.imgUrl})` }}
          onClick={() => {
            this.handleOpenSlot(info.code, info.gameCompany);
          }}
          // style='background-image: url("/assets/slot/cq9/1.png");'
        >
          <a style={{ paddingLeft: "20%" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                marginTop: "200px",
                textAlign: "center",
                color: "#fff",
              }}
            >
              {/* {info.nameEn} */}
              {info.nameKo}
            </p>
          </a>
        </li>
      );
    }

    return (
      <li
        className="SlotImg"
        style={{ backgroundImage: `url(/image${info.imgUrl})` }}
        onClick={() => {
          this.handleOpenSlot(info.code, info.gameCompany);
        }}
        // style='background-image: url("/assets/slot/cq9/1.png");'
      >
        <a style={{ paddingLeft: "20%" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginTop: "210px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            {/* {info.nameEn} */}
            {info.nameKo}
          </p>
        </a>
      </li>
    );
  };

  RenderSlot = (info: any) => {
    return (
      <li
        className={info.nameEn}
        onClick={() => {
          if (info.used === "y") {
            this.setState({ mode: Mode.none });
            this.handleGame(info.code);
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}
      >
        <a href="#" className="fade_8_1_open">
          <span>{info.nameKo}</span>
        </a>
      </li>
    );
  };

  render() {
    if (this.state.mode === Mode.none) {
      return (
        <div className="game_wrap">
          <div className="game_box">
            <div
              style={{
                textAlign: "center",
                zoom: 10,
              }}
            >
              <CircularProgress />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="game_title">
          <img src="/web/images/slot_game_title1.png" />
        </div>
        <div className="game_wrap">
          {this.state.mode === Mode.game ? (
            <div className="game_box">
              <div className="game">
                <ul>
                  {this.state.games.map((row: any) => this.RenderGame(row))}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
     
          <div className="game_box">
            <div className="game">
              <ul>
                {this.state.slots.map((row: any) => this.RenderSlot(row))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      // <div className="game_wrap">
      //   <div className="game_box">
      //     <ul className="slots">
      //       {this.state.slots.map((row: any) => this.RenderSlot(row))}
      //     </ul>
      //   </div>
      // </div>
    );
  }
}
