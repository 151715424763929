import React, { Component } from "react";

import Moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";

import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import Timer from "react-compound-timer";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import WifiIcon from "@material-ui/icons/Wifi";
import Switch from "@material-ui/core/Switch";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";

// import Iframe from "react-iframe";
import { isIOS } from "react-device-detect"

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";

import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import { FxService } from "../../service/fx.service";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { TopBar } from "../share/topBar";
import { BetBtn } from "../share/bet-btn";
// import { ConverStatus, ConverBuySell, ConverMoeny } from "../utility/help";

const drawerWidth = 240;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  paper: {
    paddingTop: 110,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomCardContent = styled(CardContent)`
  padding: 0px;
`;

const CustomBetButton = styled(Button)`
  width: 24%;
  font-size: 10px;

  box-shadow: inset 0px 1px 0px 0px #f9eca0;
  background: linear-gradient(to bottom, #f0c911 5%, #f2ab1e 100%);
  background-color: #f0c911;
  border-radius: 6px;
  border: 1px solid #e65f44;
  display: inline-block;
  cursor: pointer;
  color: #c92200;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ded17c;
`;

const CustomList = styled(List)`
  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  color: #ffffff;
  font-family: Arial;
  font-size: 12px;
  margin-top: 12px;
`;

const CustomTableCell = styled(TableCell)`
  color: white;
  width: 24%;
  font-size: 10px;
  padding: 5px;
`;

// const CustomCardContent = styled(Typography)``;

// const classes = useStyles();

interface Props {
  classes: any;
  session: any;
  user: any;
}

interface State {
  width: any;
  height: any;
  user: any;
  gameDate: any;
  minute: number;
  maxCount: number;
  page: number;
  seconds: number;
  balance: number;
  startImmediately: boolean;

  betId: string;
  betOutcomeId: string;
  betOutcomeOdds: number;
  games: any;
  slots: any;
  casiono: any;
}

class game extends Component<Props, State> {
  divElement: any;
  static propTypes: { classes: PropTypes.Validator<object> };
  // fxService = new FxService();
  // userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      games: [],
      casiono: [],
      slots: [],

      minute: 1,
      maxCount: 1,
      gameDate: "",
      page: 1,
      width: 0,
      height: 0,
      seconds: 0,
      startImmediately: false,
      balance: 0,
      user: {
        balance: 0,
      },

      betId: "",
      betOutcomeId: "",
      betOutcomeOdds: 0,
    };
  }

  componentDidMount() {
    if (this.divElement != null) {
      this.setState({
        height: this.divElement.clientHeight,
        width: this.divElement.clientWidth,
      });
    }

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ casiono: s.casiono });
      }
    });

    // this.updateGame();
    // this.updateMiniHistroy(1);
  }

  handleGame = (name: string) => {
    this.slotService.get_slot_by_company(name).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games });
      }
    });
  };

  handleOpenSlot = (code: string, gameCompany: string) => {
    this.slotService.OpenSlot(code, gameCompany).then((data: any) => {
      if (data.status === "success") {
        if (isIOS) {
          window.location.href = data.gameUrl
        } else {
          let timn = new Date().getTime()
          window.open(data.gameUrl)
        }
      } else if (data.status === "fix_server") {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoFix,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      } else {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoPermission,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      }
    });
  };

  handleOpenCasiono = (code: string) => {
    this.slotService.OpenCasino(code).then((data: any) => {
      if (data.status === "success") {
        let timn = new Date().getTime();
        if (isIOS) {
          window.location.href = data.gameUrl
        } else {
          let timn = new Date().getTime()
          window.open(data.gameUrl)
        }

        
      } else if (data.status === "fix_server") {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoFix,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      } else {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoPermission,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      }
    });
  };

  RenderGame = () => {
    let games = this.state.games;

    return (
      <Grid container spacing={1}>
        {games.map((info: any) => (
          <Grid item xs={4} sm={6}>
            {info.imgUrl?.indexOf("http") >= 0 ? (
              <img
                src={`${info.imgUrl}`}
                width="100%"
                onClick={() => {
                  this.handleOpenSlot(info.code, info.gameCompany);
                }}
              />
            ) : (
              <img
                src={`/image${info.imgUrl}`}
                width="100%"
                onClick={() => {
                  this.handleOpenSlot(info.code, info.gameCompany);
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    );
  };

  render() {
    let slots = this.state.slots;
    let casiono = this.state.casiono;
    return (
      <React.Fragment>
        <div className="contents_wrap">
          <div className="contents_box">
            <div className="con_box00"></div>

            {this.RenderGame()}

            <img
              src="/mo/images/slot_game_title1.png"
              width="90%"
              style={{ float: "right" }}
            />
            <Grid item xs={6} sm={3}></Grid>
            <Grid container spacing={1}>
              {slots.map((row: any) => (
                <Grid item xs={4} sm={6}>
                  <a
                    href="#"
                    onClick={() => {
                      this.handleGame(row.code);
                    }}
                  >
                    <img src={`/web${row.mobileImg}`} width="100%" />
                  </a>
                </Grid>
              ))}
            </Grid>

            <img
              src="/mo/images/game_title1.png"
              width="90%"
              style={{ float: "right" }}
            />

            <Grid item xs={6} sm={3}></Grid>
            <Grid container spacing={1}>
              {casiono.map((info: any) => (
                <Grid
                  item
                  xs={4}
                  sm={6}
                  onClick={() => {
                    if (info.used === "y") {
                      this.handleOpenCasiono(info.code);
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  <img src={`/web${info.mobileImg}`} width="100%" />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>

        <CssBaseline />
      </React.Fragment>
    );
  }
}

game.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Game = withStyles(styles, { withTheme: true })(game);
export default Game;

// export default withStyles(styles)(Game);

// export default withStyles(useStyles)(Game);
