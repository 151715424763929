import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../../service/balance.service";

interface Props {
  user: any;

  handleClose: () => any;
}

interface State {
  balance: number;
  pass: string;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: 0, pass: "" };
  }

  handleDoWithdraw = () => {
    if (10000 < this.state.balance % 10000) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (this.state.balance < 10000) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService
      .applyUserWithdrawV3(this.state.pass, this.state.balance)
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          confirmAlert({
            title: "출금",
            message: "출금신청을 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.props.handleClose();
                },
              },
            ],
          });
          return;
        } else if (data.status === "balance") {
          confirmAlert({
            title: "출금",
            message: "보유중인 금액보다 출금신청금액이 많습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else if (data.status === "wait") {
          confirmAlert({
            title: "출금",
            message: "대기중인 출금신청이 있습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else if (data.status === "pass") {
          confirmAlert({
            title: "출금",
            message: "환전 비빌번호를 확인해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
          return;
        } else {
          confirmAlert({
            title: "출금",
            message:
              "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {},
              },
            ],
          });
        }
      });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 97,
          background: "none",
          border: "none",
          width: "none",
        }}
        onClose={() => {
          this.props.handleClose();
          }}
      >
        {(close) => (
          <div className="fade_1_1">
            <div className="close_wrap">
              <div className="close_box">
                <a
                  href="#"
                  className="fade_1_1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/web/images/close.png" />
                </a>
              </div>
            </div>
            <div className="popup_wrap">
              <div className="popup_box">
                <div className="popup_start">
                  <div className="popup">
                    <div className="title_wrap">
                      <div className="title">
                        출금신청 <span className="title2">Money Excharge</span>{" "}
                        <span>
                          <img src="/web/images/logo.png" width="120" />
                        </span>
                      </div>
                    </div>
                    {/* <div className="con_box10">
                      <div className="tab_wrap">
                        <ul>
                          <li>
                            <a href="#">
                              <span className="tabon">입금신청</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="tab">출금신청</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">
                          수표입금시 입금처리 절대 되지 않습니다.
                        </div>
                        <div className="info3">
                          <span className="font06">
                            최소 입금액은 3만원이상, 만원단위
                          </span>
                          로 신청가능, 입금전 반드시 계좌문의 하시기바랍니다.
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="con_box10">
                      <table className="write_title_top">
                        <tr>
                          <td className="write_title">출금하실 금액</td>
                          <td className="write_basic">
                            <input
                              className="input1"
                              value={this.state.balance}
                              onChange={(e) =>
                                this.setState({
                                  balance: Number(e.target.value),
                                })
                              }
                            />
                            <a
                              onClick={() => {
                                this.setState({
                                  balance: this.state.balance + 30000,
                                });
                              }}
                            >
                              <span className="btn1_1">3만원</span>
                            </a>{" "}
                            <a
                              onClick={() => {
                                this.setState({
                                  balance: this.state.balance + 50000,
                                });
                              }}
                            >
                              <span className="btn1_1">5만원</span>
                            </a>{" "}
                            <a
                              onClick={() => {
                                this.setState({
                                  balance: this.state.balance + 100000,
                                });
                              }}
                            >
                              <span className="btn1_1">10만원</span>
                            </a>{" "}
                            <a
                              onClick={() => {
                                this.setState({
                                  balance: this.state.balance + 500000,
                                });
                              }}
                            >
                              <span className="btn1_1">50만원</span>
                            </a>{" "}
                            <a
                              onClick={() => {
                                this.setState({
                                  balance: this.state.balance + 1000000,
                                });
                              }}
                            >
                              <span className="btn1_1">100만원</span>
                            </a>{" "}
                            <a
                              onClick={() => {
                                this.setState({
                                  balance: 0,
                                });
                              }}
                            >
                              <span className="btn1_2">Clear</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="write_title">환전비번</td>
                          <td className="write_basic">
                            <input
                              className="input1"
                              value={this.state.pass}
                              onChange={(e) =>
                                this.setState({
                                  pass: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="write_title">은행</td>
                          <td className="write_basic">
                            {this.props.user.bankname}
                          </td>
                        </tr>
                        <tr>
                          <td className="write_title">출금자명</td>
                          <td className="write_basic">
                            {this.props.user.bankowner}
                          </td>
                        </tr>
                        <tr>
                          <td className="write_title">계좌번호</td>
                          <td className="write_basic">
                            {this.props.user.banknum}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info3">
                          <span className="font05">
                            출금은 3만원 이상부터 가능하며, 부분 출금이 되지
                            않습니다.
                          </span>{" "}
                          출금시 천원단위까지 전액 출금부탁드립니다.
                          <br />
                          입금자명과 출금자명이 다를경우 본인확인 요청이 있을 수
                          있습니다.
                        </div>
                      </div>
                    </div>
                    <div className="con_box10">
                      <div className="btn_wrap_center">
                        <ul>
                          <li>
                            <a
                              onClick={() => {
                                this.handleDoWithdraw();
                              }}
                            >
                              <span className="btn3_1">출금신청하기</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
