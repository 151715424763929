import React, { Component, useState } from "react";

import { HashRouter as Router, Route, Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import { createGlobalStyle } from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { NotePopup } from "./share/notepopup";

import { Game } from "./main/game";
import { UserService } from "../service/user.service";
// import "./index.css";

const GlobalStyles = createGlobalStyle`
body{
  color: #FFC107;
  background-color: #000;
}

.body{
  color: #FFC107;
}

.MuiPaper-root {
  background-color: #000;
  color: #FFC107;
}

.popup-content {
  width: 100%;
}

.MuiToolbar-gutters{
  padding :0px
}

.popup-content {
  background: none;
} 

.react-confirm-alert-overlay {
  z-index:99999
}
`;

interface Props {
  authenticated: boolean;
  session: string;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  SetCookie: (name: string, data: any) => any;
  GetCookie: (name: string) => any;
}

interface State {
  user: {
    id: string;

    in_balance: number;
    balance: number;
    point: number;
    bankname: string;
    banknum: string;
    bankowner: string;
  };

  isLoad: boolean;
  authenticated: boolean;
}

export class Web extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        id: "",
        in_balance: 0,
        balance: 0,
        point: 0,
        bankname: "",
        banknum: "",
        bankowner: "",
      },
      isLoad: false,
      authenticated: false,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.updateUser();
    }, 25000);
    this.updateUser();
  }

  updateUser = () => {
    this.userService
      .healthCheck()
      .then((s: any) => {
        if (s.status === "success") {
          let user = {
            id: s.user.id,
            in_balance: s.user.in_balance,
            balance: s.user.balance,
            point: s.user.point,
            bankname: s.user.bankname,
            banknum: s.user.banknum,
            bankowner: s.user.bankowner,
          };

          if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
            this.props.SetCookie('user', user)
            this.setState({ user: user, authenticated: true });
          }
        } else {
          window.location.hash = "/";
        }
      })
      .catch(() => {})
      .finally(() => {
        if (this.state.isLoad === false) {
          this.setState({ isLoad: true });
        }
      });
  };

  render() {
    if (this.state.isLoad === false) {
      return <CircularProgress disableShrink size={20} />;
    }

    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/">
              <Game
                authenticated={this.state.authenticated}
                user={this.state.user}
                tryLogin={this.props.tryLogin}
                tryLoginOut={() => {
                  this.setState({
                    user: {
                      id: "",
                      in_balance: 0,
                      balance: 0,
                      point: 0,
                      bankname: "",
                      banknum: "",
                      bankowner: "",
                    },
                  });
                  this.props.tryLoginOut();
                }}
              />
            </Route>
          </Switch>
          <GlobalStyles />
          <NotePopup
            SetCookie={this.props.SetCookie}
            GetCookie={this.props.GetCookie}
          ></NotePopup>

          {/*  */}
        </Router>
      </div>
    );
  }
}
