import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import

import { SlotService } from "../../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

interface Props {}

interface State {
  mode: string;
  casiono: any;
  withdraws: any;
  deposits: any;
}

export class CasinoList extends Component<Props, State> {
  slotService = new SlotService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      casiono: [],
      withdraws: [],
      deposits: [],
    };
  }

  componentDidMount() {
    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ casiono: s.casiono, mode: Mode.slot });
      }
    });
  }

  handleOpenCasiono = (code: string, company: string) => {

    this.slotService.OpenSlot(code, company).then((data: any) => {
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      } else if (data.status === "fix_server") {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoFix,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      } else {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoPermission,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      }
    });
  };

  // handleGame = (name: string) => {
  //   this.slotService.get_slot_by_company(name).then((s: any) => {
  //     if (s.status === "success") {
  //       this.setState({ games: s.games, mode: Mode.game });
  //     }
  //   });
  // };

  // RenderSlot = (info: any) => {
  //   return (
  //     <li
  //       className={info.nameEn}
  //       onClick={() => {
  //         if (info.used === "y") {
  //           this.setState({ mode: Mode.none });
  //           // this.handleGame(info.code);
  //         } else {
  //           confirmAlert({
  //             title: "점검중",
  //             message: "현재 해당게임은 점검중입니다 .",
  //             buttons: [
  //               {
  //                 label: "확인",
  //                 onClick: () => {},
  //               },
  //             ],
  //           });
  //         }
  //       }}
  //     >
  //       <a href="#" className="fade_8_1_open">
  //         <span>{info.nameKo}</span>
  //       </a>
  //     </li>
  //   );
  // };

  RenderGame = (info: any) => {
    return (
      <li
        className={info.nameEn}
        onClick={() => {
          if (info.used === "y") {
            this.handleOpenCasiono(info.code, info.gameCompany);
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}
      >
        <a className="fade_8_1_open">
          <span>{info.nameKo}</span>
        </a>
      </li>
    );
  };

  render() {
    if (this.state.mode === Mode.none) {
      return (
        <div className="game_wrap">
          <div className="game_box">
            <div
              style={{
                textAlign: "center",
                zoom: 10,
              }}
            >
              <CircularProgress />
            </div>
          </div>
        </div>
      );
    }

    // if (this.state.mode === Mode.game) {
    //   return (
    //     <div className="game_wrap">
    //       <div className="game_box">
    //         <ul className="slots">
    //           {this.state.casiono.map((row: any) => this.RenderGame(row))}
    //         </ul>
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <div className="game_wrap">
        <div className="game_title">
          <img src="/web/images/game_title1.png" />
        </div>
        <div className="game_box">
          <div className="game">
            <ul>
              {this.state.casiono.map((row: any) => this.RenderGame(row))}
            </ul>
          </div>
        </div>
      </div>

      // <div className="game_wrap">
      //   <div className="game_box">
      //     <ul className="slots">
      //       {this.state.slots.map((row: any) => this.RenderSlot(row))}
      //     </ul>
      //   </div>
      // </div>
    );
  }
}
